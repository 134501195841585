import React from 'react'

import { HeroWrapper } from '../features/Hero'
import { WhySafternetList } from '../features/WhySafternet'
import { ThreeSteps } from '../features/ThreeSteps'
import { Instructions } from '../features/Instructions'
import { Blobs } from '../features/Blobs'
import { ChooseSafternet } from '../features/ChooseSafternet'
import { Footer } from '../features/Footer'
import SEO from '../components/seo'

const IndexPage = () => (
  <>
    <SEO />
    <HeroWrapper />
    <Blobs>
      <WhySafternetList />
      <ThreeSteps />
      <Instructions />
    </Blobs>
    <ChooseSafternet />
    <Footer />
  </>
)

export default IndexPage
