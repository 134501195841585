import React from 'react'
import styled from '@emotion/styled'

import { Container } from '../../components/Container'
import { H2 } from '../../components/Typography'
import { WhySafternet } from './Item'

import Browser from '../../images/browser.svg'
import Shield from '../../images/secure.svg'
import Key from '../../images/key.svg'

const Wrapper = styled.div`
  display: grid;
  grid-gap: 110px;
  grid-template-columns: repeat(3, minmax(min-content, 220px));
  justify-content: center;

  @media only screen and (max-width: 64em) {
    grid-template-columns: 1fr;
    grid-gap: 35px;
  }
`

const data = [
  {
    img: Key,
    imgAlt: 'Key image',
    text: `<b>Limit accesses</b> to threat filled websites.`
  },
  {
    img: Shield,
    imgAlt: 'Shield image',
    text: `<b>Secure</b> your <b>environment</b> from inappropriate content.`
  },
  {
    img: Browser,
    imgAlt: 'Browser window image',
    text: `Create a <b>safer</b> internet browsing <b>experience.</b>`
  }
]

export const WhySafternetList = () => (
  <Container pb="100px" id="WhySafternet">
    <H2
      textAlign="center"
      fontSize={{ _: 4, lg: 5 }}
      mb={{ _: 5, lg: 5 }}
      mt={{ _: 5, lg: '100px' }}
    >
      Why choose <br />
      Safeternet?
    </H2>
    <Wrapper>
      {data.map((props, index) => (
        <WhySafternet {...props} key={index} />
      ))}
    </Wrapper>
  </Container>
)
