import React from 'react'
import styled from '@emotion/styled'

import { Text, H2 } from '../components/Typography'
import { Container } from '../components/Container'

export const Wrapper = styled.div`
  max-width: 600px;
  margin: 0 auto;
`

export const Instructions = () => (
  <Container>
    <Wrapper>
      <H2 textAlign="center" fontSize={{ _: 4, lg: 5 }} mb={{ _: 3, lg: 5 }} mt={{ _: 0 }}>
        Take matters into <br /> your own hands
      </H2>
      <Text textAlign="center" mb={{ _: 3, lg: 4 }} fontSize={{ _: 1, lg: 2 }}>
        The internet has such an unlimited pool of information and sources; it is safe to say that
        most of it can sadly be damaging to your surrounding environment and people.
      </Text>
      <Text textAlign="center" fontSize={{ _: 1, lg: 2 }}>
        Take an active role and make sure that malicious websites are being filtered out and blocked
        with the help of Safternet.
      </Text>
    </Wrapper>
  </Container>
)
