import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { padding } from 'styled-system'

import { Text } from '../../components/Typography'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: baseline;
  ${padding}
`

export const ImgWrapper = styled.img`
  max-width: 65px;
  width: 100%;
  margin: 0;

  @media only screen and (max-width: 64em) {
    max-width: 50px;
  }
`

export const WhySafternet = ({ img, imgAlt, text }) => (
  <Wrapper px={{ _: 5, lg: 0 }}>
    <ImgWrapper src={img} alt={imgAlt} />
    <Text
      fontSize={[1, 2]}
      mt="20px"
      textAlign="center"
      dangerouslySetInnerHTML={{ __html: text }}
    />
  </Wrapper>
)

WhySafternet.propTypes = {
  img: PropTypes.node,
  imgAlt: PropTypes.string,
  text: PropTypes.string
}
