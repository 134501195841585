import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import BlueBlob from '../images/blue_blob.svg'
import PinkBlob from '../images/pink_blob.svg'
import { Container } from '../components/Container'

export const Wrapper = styled.div`
  max-width: 2500px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
`

export const BlobWrapper = styled.div`
  position: relative;

  section {
    position: relative;
    z-index: 2;
  }

  &::before {
    content: '';
    position: absolute;
    top: 15%;
    left: 0;
    width: 600px;
    height: 600px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("${BlueBlob}");
    transform: scale(1.2) translate(-270px,-50px);
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 370px;
    height: 610px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("${PinkBlob}");
    transform: scale(2) translate(90px,0px);
  }

  @media only screen and (max-width: 64em) {
    &::before {
      width: 400px;
      height: 400px;
      transform: scale(1) translate(-210px,10px);
    }
  
    &::after {
      bottom: 0;
      right: 0;
      width: 400px;
      height: 400px;
      transform: scale(2) translate(230px,-30px);
    }
  }
  
`

export const Blobs = ({ children }) => (
  <Wrapper>
    <Container>
      <BlobWrapper>{children}</BlobWrapper>
    </Container>
  </Wrapper>
)

Blobs.propTypes = {
  children: PropTypes.node
}
