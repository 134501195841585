import React from 'react'
import styled from '@emotion/styled'
import { padding } from 'styled-system'

import { Text, H2 } from '../../components/Typography'
import { DownloadButton } from '../DownloadButton'

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr minmax(min-content, 400px);
  height: 100%;
  ${padding}
`

const Card = styled.div`
  display: grid;
  justify-items: center;
`

export const ChooseSafternet = () => (
  <Grid pt={{ _: 5, lg: '240px' }} pb={{ _: '260px', lg: '340px' }}>
    <div></div>
    <Card>
      <H2 fontSize={{ _: 4, lg: 5 }} mb={{ _: 4 }} textAlign="center">
        Choose Safternet!
      </H2>
      <Text textAlign="center" mb={{ _: 4 }}>
        For a safer internet, simply download the app.
      </Text>
      <DownloadButton />
    </Card>
  </Grid>
)
