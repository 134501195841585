import React from 'react'
import styled from '@emotion/styled'
import { padding } from 'styled-system'

import { H1, Text } from '../../components/Typography'
import { DownloadButton } from '../DownloadButton'
import { Header } from './Header'

const Grid = styled.div`
  display: grid;
  grid-template-columns: minmax(min-content, 515px) 2fr;
  justify-items: end;
  align-items: center;
  height: 100%;
  ${padding}
`

export const Hero = () => (
  <div>
    <Header />
    <Grid px={{ _: '20px', lg: 0 }} pt={{ _: 4, lg: '190px' }} pb={{ _: '350px', lg: '190px' }}>
      <div>
        <H1 fontSize={{ _: '40px', lg: '80px' }} color="textSecondary" mb={{ _: 3, lg: 3 }}>
          Create a Safer Internet
        </H1>
        <Text mb="20px" color="textSecondary">
          Make your environment safer by blocking inappropriate websites.
        </Text>
        <DownloadButton />
      </div>
    </Grid>
  </div>
)
