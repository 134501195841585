import React from 'react'
import styled from '@emotion/styled'
import { color } from 'styled-system'

import { Container } from '../../components/Container'
import { Text } from '../../components/Typography'
import { Logo } from '../Logo'

const StyledLink = styled.a`
  text-decoration: none;
  padding-left: 30px;
  ${color}
`
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

const Card = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  @media only screen and (max-width: 64em) {
    display: none;
  }
`

export const Header = () => (
  <Container pt={{ _: 3, lg: 4 }}>
    <Grid>
      <Logo />
      <Card>
        <StyledLink color="textSecondary" href="#WhySafternet">
          <Text>Why choose us</Text>
        </StyledLink>
        <StyledLink color="textSecondary" href="#TreeSteps">
          <Text>How to use</Text>
        </StyledLink>
        <StyledLink fontSize={{ _: 1 }} color="textSecondary" href="mailto:hello@safeternet.com">
          <Text>hello@safeternet.com</Text>
        </StyledLink>
      </Card>
    </Grid>
  </Container>
)
