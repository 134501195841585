import React from 'react'
import { Container, Wrapper } from '../../components/Container'
import { BackgroundHeroSection } from './BackgroundHero'

export const HeroWrapper = () => (
  <Wrapper bg="babyPurple">
    <Container px={{ _: 0, lg: '20px' }} bg="babyPurple">
      <BackgroundHeroSection />
    </Container>
  </Wrapper>
)
