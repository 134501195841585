import React from 'react'
import styled from '@emotion/styled'
import { margin } from 'styled-system'

import { Text } from '../components/Typography'
import WindowsLogo from '../images/windows-logo.svg'

export const Wrapper = styled.a`
  background-color: ${props => props.theme.colors.green};
  padding: 10px 20px;
  border-radius: 5px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: minmax(min-content, 30px) 3fr;
  align-items: center;
  max-width: 235px;
  text-decoration: none;
  ${margin}
`

export const ImgWrapper = styled.img`
  width: 30px;
  height: 30px;
  margin: 0;
`

export const DownloadButton = () => (
  <Wrapper href="https://safeternet.com/download/safeternet_setup.exe" rel="noreferrer noopener">
    <ImgWrapper src={WindowsLogo} alt="windows logo" />
    <div>
      <Text color="textSecondary" fontWeight="bold">
        Download
      </Text>
      <Text color="textSecondary">for Windows</Text>
    </div>
  </Wrapper>
)
