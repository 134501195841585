import React from 'react'
import styled from '@emotion/styled'

import { Container } from '../../components/Container'
import { H2 } from '../../components/Typography'
import { ThreeStepsImgDesktop } from './ThreeStepsImgDesktop'
import { ThreeStepsImgMobile } from './ThreeStepsImgMobile'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: baseline;
`

export const ThreeStepsWrapper = () => (
  <Container id="TreeSteps" pb={{ _: 5, lg: '100px' }}>
    <H2 textAlign="center" fontSize={{ _: 4, lg: 5 }} mb={{ _: 5, lg: 5 }} mt={{ _: 5, lg: 5 }}>
      Three-steps to a<br /> safer net
    </H2>
    <Wrapper>
      <ThreeStepsImgDesktop />
      <ThreeStepsImgMobile />
    </Wrapper>
  </Container>
)
