import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import styled from '@emotion/styled'
import BackgroundImage from 'gatsby-background-image'

import { ChooseSafternet } from './ChooseSafternet'

const Background = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "laptop_with_blobs.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage Tag="section" className={className} fluid={imageData}>
          <ChooseSafternet />
        </BackgroundImage>
      )
    }}
  />
)

Background.propTypes = {
  className: PropTypes.string
}

export const BackgroundSection = styled(Background)`
  width: 100%;
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: 70% auto;

  @media only screen and (max-width: 64em) {
    &::before,
    &::after {
      background-position: 135% 140% !important;
      background-size: 110% auto !important;
    }
  }
`
