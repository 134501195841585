import React from 'react'
import styled from '@emotion/styled'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

export const ImgWrapper = styled(Img)`
  width: 100%;
  margin: 0;
  display: none;
  max-width: 400px;

  @media only screen and (max-width: 64em) {
    display: block;
  }
`

export const ThreeStepsImgMobile = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "Three_steps_mobile.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <ImgWrapper fluid={data.placeholderImage.childImageSharp.fluid} />
}
